import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PasswordLessAuthService } from 'src/app/core/services/passwordless.service';
import jwt_decode from "jwt-decode";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { StateService } from 'src/app/core/services/state.service';
import { resendSignUpCode, signOut } from 'aws-amplify/auth';
import { LoaderService } from 'src/app/core/services/loader.service';
import { InterceptorSkipHeader } from 'src/app/core/services/auth.service';
import { MessageService } from 'src/app/core/services/message.service';

@Component({
  selector: 'app-sms-verification',
  templateUrl: './sms-verification.component.html',
  styleUrls: ['../auth-style.scss']
})
export class SMSVerificationComponent {
  @ViewChild('smsCode') smsCode;
  @Input() email: string = '';
  form: FormGroup;
  private navigationData: any;
  public loading = false;
  public allProvidersList: any[];
  public availableProviders: any[];
  public showProviderSelection = false;
  public providerPractitionerMap = {};
  public idToken: string;
  constructor(private fb: FormBuilder, private translate: TranslateService, private router: Router, private passwordLessAuthService: PasswordLessAuthService, private http: HttpClient,
    private stateService: StateService, private loaderService: LoaderService, private messageService: MessageService) {
    this.stateService.lang.subscribe(res => {
      this.translate.use(res);
    });
    let validPattern = "^[0-9]{6}$";
    this.form = fb.group({
      smsCode: ['', Validators.compose([Validators.required, Validators.pattern(validPattern)])],
    });
    this.navigationData = this.router.getCurrentNavigation().extras.state;
  }

  ngAfterViewChecked() {
    if(this.smsCode){
      this.smsCode?.nativeElement.focus();
    }
  }
  ngOnInit(): void {
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    const url = `${environment.medicalContentEndpoint}/providers`;
    const response$ = this.http.get(url, { headers }).pipe(shareReplay(1));
    response$.subscribe((response: any[]) => {
      this.allProvidersList = response;
    });
  }

  async verifySMSCode(): Promise<void> {
    try {
      if (this.form.valid) {
        this.loaderService.show();
        const authData = await this.passwordLessAuthService.confirmSignIn(this.form.value.smsCode.toString(), this.navigationData.nextStep.signInStep);

        if(authData.nextStep?.additionalInfo?.challengeType === 'SMS') {
          this.loaderService.hide();
          this.messageService.showError(this.translate.instant('passwordLess.Invalid_SMS_code'));
        } else {
          const tokens = await this.passwordLessAuthService.getTokens();
          this.idToken = tokens.idToken;
          this.setCurrentUser();
        }
      }
    } catch (error) {
      this.messageService.showError(this.translate.instant('passwordLess.Invalid_SMS_code'));
      this.loaderService.hide();
    }
  }

  async setCurrentUser() {
    const loginResponse = jwt_decode(this.idToken);
    const providers = [];
    this.providerPractitionerMap = JSON.parse(loginResponse['custom:providers']);
    Object.entries(this.providerPractitionerMap).forEach(([key]) => {
      providers.push(key);
    });
    this.availableProviders = this.filterProviderList(providers);
    if (this.availableProviders.length > 1) {
      this.showProviderSelection = true;
      this.loaderService.hide();
    } else {
      await this.navigateToOverview(this.availableProviders[0].id);
    }
  }

  filterProviderList(providers: string[]): any {
    return this.allProvidersList.filter(provider => providers.includes(provider.id));
  }

  handleProviderSelected(selectedProvider: any) {
    this.navigateToOverview(selectedProvider.id);
  }

  private async navigateToOverview(provider: string) {
    sessionStorage.setItem('providerId', provider);
    this.stateService.setProviderId = provider;

    await this.passwordLessAuthService.getPracAuthToken(this.idToken, provider);

    const url = environment.endpoint + environment.services.get_practitioner
      .replace('{providerId}', provider)
      .replace('{practitionerId}', this.providerPractitionerMap[provider]);

    this.http.get(url).pipe(shareReplay(1)).subscribe(practitionerResponse => {
      sessionStorage.setItem('currentUser', JSON.stringify(practitionerResponse));
      this.loaderService.hide();
      this.router.navigateByUrl('/overview');
    }, error => {
      console.error('Error while fetching practitioner details', error);
    });
  }

  async resendSMSCode(): Promise<void> {
    try {
      const user = await this.passwordLessAuthService.getCurrentSession();
      await resendSignUpCode({ username: this.navigationData.userName });
    } catch (error) {
      console.error('Email resend failed:', error);
    }
  }
}


