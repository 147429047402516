import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, of, from, partition, iif} from 'rxjs';
import { catchError, switchMap, map, tap, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { PasswordLessAuthService } from '../services/passwordless.service';


@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private passwordLessAuthService: PasswordLessAuthService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(catchError((error: any) => {
      if (((error.status === 403 && error.error
        && ((error.error.reason === 'expired_signature') || error.error.reason === 'ExpiredToken')) || (error.status === 401 && this.authService.getPractitionerToken() != null))) {
          return this.refreshToken(req, next);
      } else {
        return throwError(error);
      }
    })
    );
  }

  refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.passwordLessAuthService.forceRefreshSession()).pipe(
      tap(session => sessionStorage.setItem('pracAuthToken', session['pracAuthToken'])),
      mergeMap(session => this.buildHeaders(req, session)),
      mergeMap((headers: HttpHeaders) => next.handle(req.clone({headers: headers})).pipe(catchError((error: any) => {
        console.log(error); 
        return of(error);
      }))),
    catchError((err, caught) => {
        this.authService.logout();
        return throwError(err);
      })
    );
  }

  buildHeaders(req, session): Observable<HttpHeaders>{
    const pathItems = location.hash.split('/');
    if(pathItems[pathItems.length-2]==='patient') {
      const patientId = pathItems[pathItems.length-1];
      return from(this.authService.setCurrentPatientId(patientId, null)).pipe(
        map(patientAccessToken => this.setHeaders(req, patientAccessToken))
      );
    }
    return of(this.setHeaders(req, session['pracAuthToken']));   
  }

  setHeaders(req: HttpRequest<unknown>, token: string): HttpHeaders {
    if (req.url.includes('api/auth') || req.url.startsWith('/graphql') || req.url.includes('medical-contents')) {
      return req.headers.set('Authorization', [token]);
    } else {
      return req.headers.set('auth-token', `Bearer ${token}`);
    }
  }
}


